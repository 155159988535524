import BaseBean from "@/utils/BaseBean";

export interface IManualCardDataObj {
    utilInst:RepairManualCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
    cxData:Array<any>
}
export default class RepairManualCardUtil extends BaseBean{
    public dataObj:IManualCardDataObj;

    constructor(proxy:any,dataObj:IManualCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        //构建网点下拉数据
        this.dataObj.cxData=await this.utils.ToolsProviderApi.getAllCx();
    }
}